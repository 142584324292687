import { DownloadIcon, EyeIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { toIntlDateTimeFormat } from '@zedoc/date';
import { default as ProjectMilestoneSelect } from '../../../common/selectors/ProjectMilestone';
import { default as AnswersSheetSelect } from '../../../common/selectors/AnswersSheet';
import { one as oneAnswersSheet } from '../../../common/api/collections/AnswersSheets';
import AnswersSheet from '../../../common/models/AnswersSheet';
import TranslationsSelect from '../../inputs/TranslationsSelect';
import Button from '../../Button';

// dummy comment to run CI

const noop = () => {};

const DownloadCard = ({ answersSheet, onPreview, onDownload }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [answersSheetLanguage, setAnswersSheetLanguage] = useState();
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(
      answersSheet && answersSheet.milestoneId,
    ),
  );

  const handleOnPreview = onPreview ? () => onPreview(answersSheet) : null;
  const handleOnDownload = (audience) =>
    onDownload(answersSheet._id, audience, answersSheetLanguage);

  const answersSheetId = answersSheet._id;
  useDDPSubscription(
    answersSheetId && oneAnswersSheet.withParams(answersSheetId),
  );
  const answersSheetDocument = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );

  return (
    <div className="cluster-4 items-center justify-between flex-wrap">
      <div>
        <p className="text-lg font-semibold">
          {milestone && (
            <span>
              {milestone.name}
              {', '}
            </span>
          )}
          {answersSheet.questionnaireId}
        </p>
        {answersSheet.completedAt && (
          <p className="text-sm text-secondary">
            {`${t('completedAt')} ${toIntlDateTimeFormat(
              language,
              answersSheet.completedAt,
              { dateStyle: 'short', timeStyle: 'short' },
            )}`}
          </p>
        )}
      </div>
      <div className="cluster-4 items-center flex-wrap">
        {handleOnPreview && (
          <>
            <Button type="ghost" icon={<EyeIcon />} onClick={handleOnPreview}>
              {t('preview')}
            </Button>
            <div className="w-1 h-6 bg-divider" style={{ width: 1 }} />
          </>
        )}
        {onDownload && (
          <>
            <TranslationsSelect
              type="primary"
              answersSheetId={answersSheetId}
              answersSheetLanguages={
                answersSheetDocument && answersSheetDocument.language
                  ? [answersSheetDocument.language]
                  : []
              }
              onChange={setAnswersSheetLanguage}
              value={answersSheetLanguage}
              valueAsLanguage
            />
            <Button
              type="ghost"
              icon={<DownloadIcon />}
              onClick={() => handleOnDownload('patient')}
            >
              {t('patientPDF')}
            </Button>
            <Button
              icon={<DownloadIcon />}
              onClick={() => handleOnDownload('provider')}
            >
              {t('providerPDF')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

DownloadCard.propTypes = {
  answersSheet: PropTypes.instanceOf(AnswersSheet),
  onPreview: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

DownloadCard.defaultProps = {
  answersSheet: {},
};

const PatientDocuments = ({ answersSheets, onPreview, onDownload }) => {
  return (
    <div className="stack-6">
      {answersSheets.map((answersSheet, idx) => (
        <>
          {idx !== 0 && <hr className="border-divider" />}
          <DownloadCard
            key={answersSheet._id}
            answersSheet={answersSheet}
            onPreview={onPreview}
            onDownload={onDownload}
          />
        </>
      ))}
    </div>
  );
};

PatientDocuments.propTypes = {
  answersSheets: PropTypes.arrayOf(PropTypes.instanceOf(AnswersSheet)),
  onPreview: PropTypes.func,
  onDownload: PropTypes.func,
};

PatientDocuments.defaultProps = {
  answersSheets: [],
  onPreview: null,
  onDownload: noop,
};

export default PatientDocuments;
